import React, { useCallback, useEffect } from 'react';
import { useAuth } from 'lib/auth';
import { useEvents } from 'lib/events/hooks/useEvents';

export const EventsProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const { token } = useAuth();
  const [source, setSource] = useEvents();

  const connect = useCallback(() => {

    if (source) {
      source.close();
    }

    const eventsUrl = process.env.REACT_APP_API_URL + '/events/' + token;
    const eventSource = new EventSource(eventsUrl);

    eventSource.onerror = (error) => {
      console.error(error);
      eventSource.close();
      window.setTimeout(connect, 1000);
    };

    setSource(eventSource);

  }, [token, source, setSource]);

  useEffect(connect, [token]);

  if (!source) {
    return null;
  }

  return <>{children}</>;

};
