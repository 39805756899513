import { createReducerContext } from 'react-use';
import { AuthState, KeyPair } from 'lib/auth';
import { omit } from 'lodash';

export enum ActionKeys {
  Login = 'auth/login',
  Verify = 'auth/verify',
  Logout = 'auth/logout',
}

export type ActionTypes =
  | LoginAction
  | VerifyAction
  | LogoutAction
  ;

export interface LoginAction extends AuthState {
  type: ActionKeys.Login;
}

export interface VerifyAction extends AuthState {
  type: ActionKeys.Verify;
}

export interface LogoutAction {
  type: ActionKeys.Logout;
}

export const reducer = (state: AuthState = {}, action: ActionTypes): AuthState => {

  switch (action.type) {

    case ActionKeys.Login:
      return { ...state, ...omit(action, 'type') };

    case ActionKeys.Verify:
      return { ...state, ...omit(action, 'type') };
    case ActionKeys.Logout:
      return {};
    default:
      return state;
  }

};

export const createLoginAction = (userId: string, keyId: string, deviceId: string, keyPair: KeyPair, token: string, orderToken: string, stripePublicKey: string): LoginAction => {
  return { userId, keyId, deviceId, keyPair, token, orderToken, stripePublicKey, type: ActionKeys.Login };
};

export const createVerifyAction = (orderToken: string, stripePublicKey: string): VerifyAction => {
  return { orderToken, stripePublicKey, type: ActionKeys.Verify };
};

export const createLogoutAction = (): LogoutAction => {
  return { type: ActionKeys.Logout };
};

const [useAuthReducer, AuthReducerProvider] = createReducerContext(reducer, {});
export { useAuthReducer, AuthReducerProvider };
