import React, { useState } from 'react';
import { IonButton, IonInput, IonItem, IonLabel } from '@ionic/react';
import { Page } from 'components/Page/Page';
import styles from './Login.module.scss';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Alert } from 'components/Alert/Alert';
import { useApi } from 'lib/api';
import { useHistory } from 'react-router';

export const Reset: React.FC = () => {

  const { auth: { reset } } = useApi();

  const [error, setError] = useState<any>();

  const history = useHistory();

  const validationSchema = yup.object({
    email: yup.string().email('Keine gültige E-Mail Adresse').required('E-Mail Adresse eingeben'),
  });

  return (
    <Page>

      <div className={styles.wrapper}>

        <h2>Kennwort zurücksetzen</h2>

        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              await reset(values);
              history.push({ pathname: '/login', state: { reset: true } });
            } catch (e: any) {
              setError(e.error);
            }
          }}
        >

          {({ isSubmitting, errors, touched, values, handleSubmit, handleChange }) => (

            <form onSubmit={handleSubmit}>

              <IonItem className={'ion-no-padding'}>
                <IonLabel position="stacked">E-Mail-Adresse</IonLabel>
                <IonInput
                  name={'email'}
                  type={'email'}
                  value={values.email}
                  onIonChange={handleChange}
                />
              </IonItem>

              {((touched.email && errors.email)) && (
                <Alert type={'error'} messages={errors.email}/>
              )}

              {error && (
                <Alert type={'error'} messages={error.message}/>
              )}

              <IonButton expand={'block'} type={'submit'} className={'ion-margin-top'} disabled={isSubmitting}>
                Zurücksetzen
              </IonButton>

            </form>

          )}

        </Formik>

        <p className={'ion-text-center'}>- oder -</p>

        <div className={'ion-text-center'}>
          <IonButton className={'small'} routerLink={'/login'} routerDirection={'root'}>Anmelden</IonButton>
        </div>

      </div>

    </Page>
  );
};
