import React, { useEffect, useMemo, useState } from 'react';
import { StripeInitializeResponseDto, useApi } from 'lib/api';
import { Page } from 'components/Page/Page';
import { IonButton, IonRouterLink, useIonLoading } from '@ionic/react';
import styles from './DepositPage.module.scss';
import { Elements } from '@stripe/react-stripe-js';
import { CheckoutForm } from './CheckoutForm';
import { useAuth } from 'lib/auth';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation } from 'react-router';
import cx from 'classnames';

export const DepositPage: React.FC = () => {

  const [initialized, setInitialized] = useState<StripeInitializeResponseDto>();

  const [processing, dismissProgressing] = useIonLoading();

  const { transactions: { initializeStripeTransaction } } = useApi();

  const { stripePublicKey } = useAuth();
  const stripePromise = useMemo(() => loadStripe(stripePublicKey!), [stripePublicKey]);

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== '/deposit') {
      setInitialized(undefined);
    }
  }, [pathname]);

  const initStripe = async (amount: number) => {
    await processing({ message: 'Transaktion wird gestartet' });
    try {
      setInitialized((await initializeStripeTransaction({ amount })).data);
    } catch (e) {
      console.error(e);
    }
  };

  return (

    <Page>

      <div className={styles.amountContainer}>

        {!initialized && (
          <div className={'ion-text-center'}>

            <h2>Guthaben aufladen</h2>

            {[5, 10, 20, 50].map((p, idx) => (
              <IonButton key={p} expand={'block'} onClick={() => initStripe(p)} className={cx({ 'ion-margin-top': idx !== 0, 'ion-margin-bottom': idx === 3 })}>
                &euro; {p}
              </IonButton>
            ))}

          </div>
        )}

        {initialized && (
          <Elements stripe={stripePromise} options={{ clientSecret: initialized.clientSecret, appearance: { theme: 'stripe' }, locale: 'de' }}>
            <CheckoutForm amount={initialized.amount} return_url={initialized.returnUrl} onReady={() => dismissProgressing()}/>
          </Elements>
        )}

        <div className={'ion-text-center ion-margin-top'}>
          <IonRouterLink routerLink={'/scan'} onClick={() => setInitialized(undefined)}>
            Abbrechen
          </IonRouterLink>
        </div>

      </div>

    </Page>
  );
};
