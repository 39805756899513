import React, { ComponentType, FC } from 'react';

type ComposeComponent = ComponentType<{ children?: React.ReactNode }> | [ComponentType<{ children?: React.ReactNode }>, { [key: string]: any }];

interface Props {
  components: ComposeComponent[];
  children?: React.ReactNode;
}

export const Compose: FC<Props> = ({ components, children }) => (
  <>
    {components.reduceRight((acc, curr) => {
      const [Provider, props] = Array.isArray(curr) ? [curr[0], curr[1]] : [curr, {}];
      return <Provider {...props}>{acc}</Provider>;
    }, children)}
  </>
);
