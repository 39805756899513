import { useEffect, useRef } from 'react';
import { useInterval } from 'react-use';

export const useOnAwake = (callback: () => void, interval: number) => {

  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  let lastTime = (new Date()).getTime();

  useInterval(() => {
    const currentTime = (new Date()).getTime();
    if (currentTime > (lastTime + interval * 2)) {
      // Probably just woke up!
      savedCallback.current?.();
    }
    lastTime = currentTime;
  }, interval);
};
