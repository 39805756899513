import React, { useContext, useState } from 'react';
import { IonButton, IonItem, IonLabel, IonRouterLink, NavContext, useIonToast } from '@ionic/react';
import { Page } from 'components/Page/Page';
import { PasswordInput } from 'components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Alert } from 'components/Alert/Alert';
import { useApi } from 'lib/api';

export const ChangePasswordPage: React.FC = () => {

  const { users: { changePassword } } = useApi();

  const [error, setError] = useState<any>();

  const [presentCompleted] = useIonToast();
  const { navigate } = useContext(NavContext);

  const validationSchema = yup.object({
    oldPassword: yup.string().required('Bitte geben Sie das alte Passwort ein'),
    newPassword: yup.string().required('Bitte geben Sie ein neues Passwort ein').min(8, 'Das neue Passwort muss mindestens 8 Zeichen lang sein'),
  });

  return (
    <Page>

      <h2>Kennwort ändern</h2>

      <Formik
        initialValues={{
          oldPassword: '',
          newPassword: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          try {
            await changePassword(values, { format: 'json' });
            presentCompleted({
              color: 'dark',
              duration: 5000,
              message: 'Passwort erfolgreich geändert',
            });
            navigate('/profile', 'back');
          } catch (e: any) {
            setError(e.error);
          }
        }}
      >

        {({ isSubmitting, errors, touched, values, handleSubmit, handleChange }) => (

          <form onSubmit={handleSubmit}>

            <IonItem className={'ion-no-padding'}>
              <IonLabel position="stacked">Altes Kennwort</IonLabel>
              <PasswordInput
                name={'oldPassword'}
                value={values.oldPassword}
                onChange={handleChange}
              />
            </IonItem>

            <IonItem className={'ion-no-padding'}>
              <IonLabel position="stacked">Neues Kennwort</IonLabel>
              <PasswordInput
                name={'newPassword'}
                value={values.newPassword}
                onChange={handleChange}
              />
            </IonItem>

            <div className={'ion-padding-top'}>

              <IonButton expand={'block'} type={'submit'} className={'ion-margin-top'} disabled={isSubmitting}>
                Speichern
              </IonButton>

              <div className={'ion-text-center ion-margin-top'}>
                <IonRouterLink routerLink={'/profile'}>
                  Abbrechen
                </IonRouterLink>
              </div>

            </div>

            {(touched.oldPassword && errors.oldPassword) && (
              <Alert type={'error'} messages={errors.oldPassword}/>
            )}

            {(touched.newPassword && errors.newPassword) && (
              <Alert type={'error'} messages={errors.newPassword}/>
            )}

            {error && (
              <Alert type={'error'} messages={error.message}/>
            )}

          </form>

        )}

      </Formik>

    </Page>
  );
};
