import React from 'react';
import { useAuth } from 'lib/auth';
import { IonModal } from '@ionic/react';
import { ChangeEmailForm } from 'components/ChangeEmail/ChangeEmailForm';
import { Page } from 'components/Page/Page';
import styles from './ChangeEmailModal.module.scss';
import { createGlobalState } from 'react-use';

export const useChangeEmailModal = createGlobalState<boolean>(false);

export const ChangeEmailModal: React.FC = () => {

  const { logout } = useAuth();
  const [visible, setVisible] = useChangeEmailModal();

  return (
    <IonModal isOpen={visible} backdropDismiss={false} cssClass={styles.modal}>
      <Page className={'ion-padding-top'}>

        <div className={'ion-text-center'}>
          <h2>E-Mail-Adresse ändern</h2>
          <p>
            Sie müssen Ihre E-Mail Adresse ändern, da Sie eine automatisch zugewiesene E-Mail-Adresse haben.
          </p>
        </div>

        <ChangeEmailForm onSuccess={() => setVisible(false)} onCancel={logout}/>

      </Page>

    </IonModal>
  );

};
