import React, { useCallback, useEffect, useState } from 'react';
import { EventType, TransactionEntity, TransactionStatus, useApi, useApiCall } from 'lib/api';
import { useEvents } from 'lib/events/hooks/useEvents';
import { IonicSafeString, useIonToast } from '@ionic/react';
import { useInterval } from 'react-use';

export const TransactionsProvider: React.FC<{ children?: React.ReactNode; }> = ({ children }) => {

  const { transactions: { getTransactionNotifications, dismissTransactionById } } = useApi();
  const transactions = useApiCall(() => getTransactionNotifications());

  const [eventSource] = useEvents();
  const [present] = useIonToast();

  const [presented, setPresented] = useState<string[]>([]);

  const showNotification = useCallback((transaction: TransactionEntity) => {

    let message: string | IonicSafeString = '';
    let color: string = '';

    if (transaction.status === TransactionStatus.Completed) {
      message = `€ ${transaction.amount} wurden Ihnen gutgeschrieben!`;
      color = 'dark';
    } else if (transaction.status === TransactionStatus.Failed) {
      message = `Die Transaktion über € ${transaction.amount} war nicht erfolgreich!`;
      color = 'danger';
    } else {
      return;
    }

    if (!presented.includes(transaction.id)) {

      setPresented([...presented, transaction.id]);

      present({
        color,
        message,
        onDidDismiss: () => dismissTransactionById(transaction.id),
        buttons: [
          {
            text: 'X',
            role: 'cancel',
          },
        ],
      });
    }

  }, [present, dismissTransactionById, presented, setPresented]);

  useEffect(() => {
    if (transactions?.data?.length! > 0) {
      transactions.data?.map(transaction => showNotification(transaction));
    }
  }, [transactions.data, showNotification]);

  useInterval(() => transactions.reload(), 60e3);

  const onTransactionNotification = useCallback((event: any) => {
    showNotification(JSON.parse(event.data));
  }, [showNotification]);

  useEffect(() => {
    eventSource.addEventListener(EventType.TransactionNotification, onTransactionNotification);
    return () => {
      eventSource.removeEventListener(EventType.TransactionNotification, onTransactionNotification);
    };
  }, [eventSource, onTransactionNotification]);

  return <>{children}</>;

};
