import React, { useState } from 'react';
import { IonButton, IonInput, IonItem, IonLabel, IonRouterLink } from '@ionic/react';
import { useAuth } from 'lib/auth';
import { Page } from 'components/Page/Page';
import styles from './Login.module.scss';
import { PasswordInput } from 'components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Alert } from 'components/Alert/Alert';
import { useLocation } from 'react-router';
import claim from 'images/claim.svg';
import earth from 'images/earth.svg';
import cx from 'classnames';

interface LoginLocationState {
  reset?: boolean;
}

export const Login: React.FC = () => {

  const { login } = useAuth();

  const [error, setError] = useState<any>();

  const { state: locationState } = useLocation<LoginLocationState>();

  const validationSchema = yup.object({
    email: yup.string().email('Keine gültige E-Mail Adresse').required('E-Mail Adresse eingeben'),
    password: yup.string().required('Bitte geben Sie ein Passwort ein'),
  });

  return (
    <Page>

      <div className={styles.wrapper}>

        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={validationSchema}
          onSubmit={async ({ email, password }) => {
            try {
              await login({ email: email.trim(), password });
            } catch (e: any) {
              setError(e.error);
            }
          }}
        >

          {({ isSubmitting, submitCount, errors, touched, values, handleSubmit, handleChange }) => (

            <form onSubmit={handleSubmit}>

              <IonItem className={cx('ion-no-padding', { 'has-error': ((touched.email && errors.email) || (touched.password && errors.password)) || error })}>
                <IonLabel position="stacked">E-Mail-Adresse</IonLabel>
                <IonInput
                  name={'email'}
                  type={'email'}
                  value={values.email}
                  onIonChange={handleChange}
                />
              </IonItem>

              <IonItem className={cx('ion-no-padding', { 'has-error': ((touched.email && errors.email) || (touched.password && errors.password)) || error })}>
                <IonLabel position="stacked">Kennwort</IonLabel>
                <PasswordInput
                  name={'password'}
                  value={values.password}
                  onChange={handleChange}
                />
              </IonItem>

              <div className={'ion-text-end ion-margin-top'}>
                <IonRouterLink routerLink={'/reset'} routerDirection={'root'}>
                  Vergessen?
                </IonRouterLink>
              </div>

              {((touched.email && errors.email) || (touched.password && errors.password)) && (
                <Alert type={'error'} messages={'Bitte überprüfen Sie Ihre Eingabe'}/>
              )}

              {error && (
                <Alert type={'error'} messages={error.statusCode === 401 ? 'Keine gültigen Anmeldedaten' : 'Login fehlgeschlagen'}/>
              )}

              {locationState?.reset && submitCount === 0 && (
                <Alert type={'success'} messages={'Ihr Passwort wurde zurückgesetzt und per E-Mail versendet.'}/>
              )}

              <IonButton expand={'block'} type="submit" className={'ion-margin-top'} disabled={isSubmitting}>
                Anmelden
              </IonButton>

            </form>

          )}

        </Formik>

        <p className={'ion-text-center'}>- oder -</p>

        <div className={'ion-text-center'}>
          <IonButton className={'small'} routerLink={'/register'} routerDirection={'root'}>Registrieren</IonButton>
        </div>

        <div className={'bottom-addon'}>
          <img src={claim} alt={'Bettidrink claim'}/>
          <img src={earth} className={'ion-margin-top'} height={208} alt={"Bettidrink earth"}/>
        </div>

      </div>

    </Page>
  );
};
