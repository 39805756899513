/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum DeviceType {
  Web = "web",
  Ios = "ios",
  Android = "android",
  Analog = "analog",
}

export interface LoginDto {
  email: string;
  password: string;
  deviceId: string;
  deviceType: DeviceType;
  deviceName?: string;
  deviceModel?: string;
  deviceManufacturer?: string;
  publicKey: string;
}

export interface LoginResponseDto {
  userId: string;
  keyId: string;
  orderToken: string;
  stripePublicKey: string;
}

export type BadRequestException = object;

export type UnauthorizedException = object;

export interface RegisterDto {
  email: string;
  firstName: string;
  lastName: string;
  /** @minLength 8 */
  password: string;
  deviceId: string;
  deviceType: DeviceType;
  deviceName?: string;
  deviceModel?: string;
  deviceManufacturer?: string;
  publicKey: string;
}

export type EmailExistsException = object;

export interface LoginAdminDto {
  email: string;
  password: string;
}

export interface LoginAdminResponseDto {
  accessToken: string;
  refreshToken: string;
}

export interface ResetDto {
  email: string;
}

export interface LocationEntity {
  /**
   * @format date-time
   * @example "2020-12-31T00:00:00.000Z"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2020-12-31T00:00:00.000Z"
   */
  updatedAt: string;
  id: string;
  name: string;
  address: string;
  machines: MachineEntity[];
}

export enum SensorDataType {
  UvSecond = "uv_second",
  EmptySensor = "empty_sensor",
  HumiditySensor = "humidity_sensor",
  SewageSensor = "sewage_sensor",
  Variety = "variety",
}

export interface SensorDataEntity {
  /**
   * @format date-time
   * @example "2020-12-31T00:00:00.000Z"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2020-12-31T00:00:00.000Z"
   */
  updatedAt: string;
  id: string;
  type: SensorDataType;
  beverage?: string;
  value: number;
  machineId: string;
  machine: MachineEntity;
}

export interface MachineEntity {
  /**
   * @format date-time
   * @example "2020-12-31T00:00:00.000Z"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2020-12-31T00:00:00.000Z"
   */
  updatedAt: string;
  id: string;
  isActive: boolean;
  name: string;
  locationId: number;
  location: LocationEntity;
  token: string;
  orders: OrderEntity[];
  sensorData: SensorDataEntity[];
  transactions: TransactionEntity[];
}

export enum Beverage {
  BioApfel = "bio_apfel",
  BioHolunder = "bio_holunder",
  BioOrange = "bio_orange",
  Kraeuter = "kraeuter",
  IceteaPfirsich = "icetea_pfirsich",
  GruenerTee = "gruener_tee",
  Energy = "energy",
  Skiwasser = "skiwasser",
  SweetLemon = "sweet_lemon",
  KirscheZuckerfrei = "kirsche_zuckerfrei",
  MinzeZuckerfrei = "minze_zuckerfrei",
  MaracujaZuckerfrei = "maracuja_zuckerfrei",
  GurkeZuckerfrei = "gurke_zuckerfrei",
  IngwerZuckerfrei = "ingwer_zuckerfrei",
}

export enum OrderStatus {
  Pending = "pending",
  Completed = "completed",
  Failed = "failed",
  Cancelled = "cancelled",
}

export interface OrderEntity {
  /**
   * @format date-time
   * @example "2020-12-31T00:00:00.000Z"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2020-12-31T00:00:00.000Z"
   */
  updatedAt: string;
  id: string;
  machineId: string;
  machine: MachineEntity;
  beverage: Beverage;
  userId: string;
  userKeyId: string;
  user: UserEntity;
  userKey: UserKeyEntity;
  transactionId: string;
  transaction: TransactionEntity;
  specification: string;
  factor: number;
  status: OrderStatus;
}

export enum TransactionStatus {
  Initialized = "initialized",
  Pending = "pending",
  Completed = "completed",
  Cancelled = "cancelled",
  Failed = "failed",
}

export enum TransactionType {
  Order = "order",
  Deposit = "deposit",
  Refund = "refund",
}

export interface TransactionEntity {
  /**
   * @format date-time
   * @example "2020-12-31T00:00:00.000Z"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2020-12-31T00:00:00.000Z"
   */
  updatedAt: string;
  id: string;
  user: UserEntity;
  userKey: UserKeyEntity;
  userId: string;
  userKeyId: string;
  order: OrderEntity;
  amount: number;
  status: TransactionStatus;
  type: TransactionType;
  machineId: string;
  machine: MachineEntity;
  info: string;
  needsAlert: boolean;
  stripeId: string;
  printDate: string;
  printType: string;
  printDetail: string;
  printAmount: string;
}

export interface BalanceViewEntity {
  userId: string;
  deposits: number;
  pendingDeposits: number;
  orders: number;
  refunds: number;
  depositCount: number;
  orderCount: number;
  refundCount: number;
  balance: number;
}

export interface BeveragesViewEntity {
  userId: string;
  beverage: Beverage;
  amount: number;
}

export interface UserEntity {
  /**
   * @format date-time
   * @example "2020-12-31T00:00:00.000Z"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2020-12-31T00:00:00.000Z"
   */
  updatedAt: string;
  id: string;
  isActive: boolean;
  softDeleted: boolean;
  isAdmin: boolean;
  isStripeTestUser: boolean;
  email: string;
  /** @minLength 8 */
  password: string;
  firstName: string;
  lastName: string;
  stripeCustomerId: string;
  keys: UserKeyEntity[];
  transactions: TransactionEntity[];
  orders: OrderEntity[];
  balance: BalanceViewEntity;
  beverages: BeveragesViewEntity;
  fullName: string;
}

export interface UserKeyEntity {
  id: string;
  publicKey: string;
  deviceId: string;
  deviceType: DeviceType;
  /** The device model. For example, “iPhone” */
  deviceName: string;
  /** The device model. For example, “iPhone” */
  deviceModel: string;
  /** The manufacturer of the device. */
  deviceManufacturer: string;
  user: UserEntity;
  transactions: TransactionEntity[];
  orders: OrderEntity[];
}

export interface UserBeverageCount {
  beverage: Beverage;
  amount: number;
}

export interface UserBeveragesDto {
  total: number;
  beverages: UserBeverageCount[];
}

export enum TransactionPeriod {
  CurrentMonth = "current-month",
  LastMonth = "last-month",
  Total = "total",
}

export interface UserCreateAnalogResponseDto {
  userId: string;
  email: string;
  password: string;
  token: string;
}

export interface UserChangeEmailDto {
  email: string;
}

export interface UserChangePasswordDto {
  oldPassword: string;
  newPassword: string;
}

export interface UserAddRefundDto {
  amount: number;
}

export interface UserCreateDto {
  email: string;
  password: string;
  firstName?: string;
  lastName?: string;
}

export interface OrderAcceptRequestDto {
  variation: Beverage;
  price: number;
  specification: string;
}

export interface OrderAcceptResponseDto {
  /** @example "fdf35b78-6216-4d40-a7eb-9d88f6b8fdbf" */
  id: string;
}

export type InsufficientBalanceException = object;

export type UnexpectedOrderStatusException = object;

export interface OrderCompleteRequestDto {
  factor: number;
}

export interface StripeInitializeRequestDto {
  /**
   * @min 10
   * @max 100
   */
  amount: number;
}

export interface StripeInitializeResponseDto {
  amount: number;
  returnUrl: string;
  clientSecret: string;
}

export type NotFoundException = object;

export interface LogDto {
  message: string;
}

export interface SensorDataDto {
  type: SensorDataType;
  beverage?: string;
  value: number;
  /**
   * @format date-time
   * @example "2020-12-31T00:00:00.000Z"
   */
  createdAt: string;
}

export interface StatisticsUsersDto {
  total: number;
  balance: number;
  active: number;
}

export interface StatisticsBeveragesRowDto {
  beverage: Beverage;
  total: number;
}

export enum StatisticsInterval {
  Today = "today",
  LastWeek = "last_week",
  LastMonth = "last_month",
  LastYear = "last_year",
}

export interface StatisticsBeveragesResponseDto {
  beverages: StatisticsBeveragesRowDto[];
  interval: StatisticsInterval;
}

export interface JwtTokenDto {
  /** user id */
  uid: string;
  /** issuer */
  iss: string;
  /**
   * Issued at (timestamp)
   * @example 1422779638
   */
  iat: number;
  /**
   * expires at
   * @example 1422779890
   */
  exp: number;
  /** token subject */
  subject: string;
}

export enum EventType {
  OrderAccepted = "order_accepted",
  OrderCompleted = "order_completed",
  OrderCanceled = "order_canceled",
  BalanceChanged = "balance_changed",
  DepositFinished = "deposit_finished",
  TransactionNotification = "transaction_notification",
}

export interface EventDto {
  type: EventType;
  userId: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public async request<T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  }
}

/**
 * @title Bettidrink
 * @version 1.0
 * @contact
 *
 * The bettidrink API description
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  auth = {
    /**
     * No description
     *
     * @tags Auth
     * @name Login
     * @summary Login
     * @request POST:/auth/login
     */
    login: (data: LoginDto, params: RequestParams = {}) =>
      this.request<LoginResponseDto, BadRequestException | UnauthorizedException>({
        path: `/auth/login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name Verify
     * @summary Verify
     * @request GET:/auth/verify
     */
    verify: (params: RequestParams = {}) =>
      this.request<LoginResponseDto, BadRequestException | UnauthorizedException>({
        path: `/auth/verify`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name Register
     * @summary Register
     * @request POST:/auth/register
     */
    register: (data: RegisterDto, params: RequestParams = {}) =>
      this.request<LoginResponseDto, BadRequestException | UnauthorizedException | EmailExistsException>({
        path: `/auth/register`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name Logout
     * @summary Logout
     * @request POST:/auth/logout
     */
    logout: (params: RequestParams = {}) =>
      this.request<void, UnauthorizedException>({
        path: `/auth/logout`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name LoginAdmin
     * @summary Login Admin
     * @request POST:/auth/loginAdmin
     */
    loginAdmin: (data: LoginAdminDto, params: RequestParams = {}) =>
      this.request<LoginAdminResponseDto, BadRequestException | UnauthorizedException>({
        path: `/auth/loginAdmin`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name RefreshAdmin
     * @summary Refresh Admin Token
     * @request POST:/auth/refresh
     */
    refreshAdmin: (params: RequestParams = {}) =>
      this.request<LoginAdminResponseDto, any>({
        path: `/auth/refresh`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name Reset
     * @summary Reset Password
     * @request POST:/auth/reset
     */
    reset: (data: ResetDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/reset`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  users = {
    /**
     * No description
     *
     * @tags Users
     * @name VerifyUserOrderToken
     * @summary Verify Order Token
     * @request GET:/users/verify/token/{token}
     */
    verifyUserOrderToken: (token: string, params: RequestParams = {}) =>
      this.request<void, UnauthorizedException>({
        path: `/users/verify/token/${token}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name GetProfile
     * @summary Get profile
     * @request GET:/users/profile
     */
    getProfile: (params: RequestParams = {}) =>
      this.request<UserEntity, any>({
        path: `/users/profile`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name GetBalance
     * @summary Get balance
     * @request GET:/users/balance
     */
    getBalance: (params: RequestParams = {}) =>
      this.request<BalanceViewEntity, any>({
        path: `/users/balance`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name GetBeverages
     * @summary Get beverages
     * @request GET:/users/beverages
     */
    getBeverages: (params: RequestParams = {}) =>
      this.request<UserBeveragesDto, any>({
        path: `/users/beverages`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name GetTransactionsPdf
     * @summary Get transactions pdf
     * @request GET:/users/transactions/{period}
     */
    getTransactionsPdf: (period: TransactionPeriod, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/transactions/${period}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name GetTransactionsHtml
     * @summary Get transactions html
     * @request GET:/users/transactions/html/{period}
     */
    getTransactionsHtml: (period: TransactionPeriod, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/transactions/html/${period}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name CreateMultipleAnalogUsers
     * @summary Create multiple analog user
     * @request POST:/users/analog/{count}
     */
    createMultipleAnalogUsers: (count: number, params: RequestParams = {}) =>
      this.request<UserCreateAnalogResponseDto, any>({
        path: `/users/analog/${count}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name ChangeEmail
     * @summary Change user email
     * @request PATCH:/users/email
     */
    changeEmail: (data: UserChangeEmailDto, params: RequestParams = {}) =>
      this.request<any, BadRequestException | EmailExistsException | void>({
        path: `/users/email`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name ChangePassword
     * @summary Change user password
     * @request PATCH:/users/password
     */
    changePassword: (data: UserChangePasswordDto, params: RequestParams = {}) =>
      this.request<any, BadRequestException | void>({
        path: `/users/password`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name AddRefund
     * @summary Add refund transaction to user
     * @request POST:/users/{userId}/refund
     */
    addRefund: (userId: string, data: UserAddRefundDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/${userId}/refund`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name RequestAccountDeletion
     * @request DELETE:/users/account
     */
    requestAccountDeletion: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/account`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name CreateUser
     * @summary Create User
     * @request POST:/users
     */
    createUser: (data: UserCreateDto, params: RequestParams = {}) =>
      this.request<UserEntity, any>({
        path: `/users`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name ListUsers
     * @summary List Users
     * @request GET:/users
     */
    listUsers: (
      query?: {
        limit?: number;
        page?: number;
        search?: string;
        isActive?: boolean;
        isAdmin?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        | {
            items: UserEntity[];
            meta: {
              itemCount: number;
              totalItems: number;
              itemsPerPage: number;
              totalPages: number;
              currentPage: number;
            };
          }
        | UserEntity[],
        any
      >({
        path: `/users`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name BulkCreateUsers
     * @summary Bulk Create Users
     * @request POST:/users/bulk
     */
    bulkCreateUsers: (data: UserEntity[], params: RequestParams = {}) =>
      this.request<UserEntity[], any>({
        path: `/users/bulk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name BulkRemoveUsers
     * @summary Bulk Remove Users
     * @request DELETE:/users/bulk
     */
    bulkRemoveUsers: (
      query: {
        /** IDs */
        ids: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/users/bulk`,
        method: "DELETE",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name ReadUser
     * @summary Read User
     * @request GET:/users/{id}
     */
    readUser: (id: string, params: RequestParams = {}) =>
      this.request<UserEntity, any>({
        path: `/users/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UpdateUser
     * @summary Update User
     * @request PATCH:/users/{id}
     */
    updateUser: (id: string, data: UserEntity, params: RequestParams = {}) =>
      this.request<UserEntity, any>({
        path: `/users/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name DeleteUser
     * @summary Delete User
     * @request DELETE:/users/{id}
     */
    deleteUser: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name ExportUsersCsv
     * @summary Export Users Csv
     * @request GET:/users/export/csv
     */
    exportUsersCsv: (
      query?: {
        search?: string;
        limit?: number;
        page?: number;
        isActive?: boolean;
        isAdmin?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/users/export/csv`,
        method: "GET",
        query: query,
        ...params,
      }),
  };
  orders = {
    /**
     * No description
     *
     * @tags Orders
     * @name AcceptOrder
     * @summary Accept Order
     * @request POST:/orders/{token}
     */
    acceptOrder: (token: string, data: OrderAcceptRequestDto, params: RequestParams = {}) =>
      this.request<OrderAcceptResponseDto, InsufficientBalanceException | UnexpectedOrderStatusException>({
        path: `/orders/${token}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name CompleteOrder
     * @summary Complete Order
     * @request POST:/orders/{token}/{orderId}
     */
    completeOrder: (token: string, orderId: string, data: OrderCompleteRequestDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/orders/${token}/${orderId}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name CancelOrder
     * @summary Cancel Order
     * @request DELETE:/orders/{token}/{orderId}
     */
    cancelOrder: (token: string, orderId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/orders/${token}/${orderId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name CreateOrder
     * @summary Create Order
     * @request POST:/orders
     */
    createOrder: (data: OrderEntity, params: RequestParams = {}) =>
      this.request<OrderEntity, any>({
        path: `/orders`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name ListOrders
     * @summary List Orders
     * @request GET:/orders
     */
    listOrders: (
      query: {
        limit?: number;
        page?: number;
        search?: string;
        userId?: string;
        machineId?: string;
        beverage: Beverage;
        status?: OrderStatus;
        year?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        | {
            items: OrderEntity[];
            meta: {
              itemCount: number;
              totalItems: number;
              itemsPerPage: number;
              totalPages: number;
              currentPage: number;
            };
          }
        | OrderEntity[],
        any
      >({
        path: `/orders`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name BulkCreateOrders
     * @summary Bulk Create Orders
     * @request POST:/orders/bulk
     */
    bulkCreateOrders: (data: OrderEntity[], params: RequestParams = {}) =>
      this.request<OrderEntity[], any>({
        path: `/orders/bulk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name BulkRemoveOrders
     * @summary Bulk Remove Orders
     * @request DELETE:/orders/bulk
     */
    bulkRemoveOrders: (
      query: {
        /** IDs */
        ids: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/orders/bulk`,
        method: "DELETE",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name ReadOrder
     * @summary Read Order
     * @request GET:/orders/{id}
     */
    readOrder: (id: string, params: RequestParams = {}) =>
      this.request<OrderEntity, any>({
        path: `/orders/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name UpdateOrder
     * @summary Update Order
     * @request PATCH:/orders/{id}
     */
    updateOrder: (id: string, data: OrderEntity, params: RequestParams = {}) =>
      this.request<OrderEntity, any>({
        path: `/orders/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name DeleteOrder
     * @summary Delete Order
     * @request DELETE:/orders/{id}
     */
    deleteOrder: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/orders/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name ExportOrdersCsv
     * @summary Export Orders Csv
     * @request GET:/orders/export/csv
     */
    exportOrdersCsv: (
      query: {
        search?: string;
        limit?: number;
        page?: number;
        userId?: string;
        machineId?: string;
        beverage: Beverage;
        status?: OrderStatus;
        year?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/orders/export/csv`,
        method: "GET",
        query: query,
        ...params,
      }),
  };
  transactions = {
    /**
     * No description
     *
     * @tags Transactions
     * @name InitializeStripeTransaction
     * @summary Initialize Stripe Transaction
     * @request POST:/transactions/stripe
     */
    initializeStripeTransaction: (data: StripeInitializeRequestDto, params: RequestParams = {}) =>
      this.request<StripeInitializeResponseDto, UnauthorizedException>({
        path: `/transactions/stripe`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Transactions
     * @name StripeWebhook
     * @summary Webhook for Stripe Transaction
     * @request POST:/transactions/webhook
     */
    stripeWebhook: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/transactions/webhook`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Transactions
     * @name DismissTransactionById
     * @summary Dismiss Transaction By ID
     * @request DELETE:/transactions/dismiss/{id}
     */
    dismissTransactionById: (id: string, params: RequestParams = {}) =>
      this.request<any, UnauthorizedException | NotFoundException>({
        path: `/transactions/dismiss/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Transactions
     * @name GetTransactionNotifications
     * @summary Get Transactions for user that needs notification
     * @request GET:/transactions/notifications
     */
    getTransactionNotifications: (params: RequestParams = {}) =>
      this.request<TransactionEntity[], any>({
        path: `/transactions/notifications`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Transactions
     * @name CreateTransaction
     * @summary Create Transaction
     * @request POST:/transactions
     */
    createTransaction: (data: TransactionEntity, params: RequestParams = {}) =>
      this.request<TransactionEntity, any>({
        path: `/transactions`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Transactions
     * @name ListTransactions
     * @summary List Transactions
     * @request GET:/transactions
     */
    listTransactions: (
      query: {
        limit?: number;
        page?: number;
        search?: string;
        userId?: string;
        machineId?: string;
        year?: number;
        status: TransactionStatus;
        type: TransactionType;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        | {
            items: TransactionEntity[];
            meta: {
              itemCount: number;
              totalItems: number;
              itemsPerPage: number;
              totalPages: number;
              currentPage: number;
            };
          }
        | TransactionEntity[],
        any
      >({
        path: `/transactions`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Transactions
     * @name BulkCreateTransactions
     * @summary Bulk Create Transactions
     * @request POST:/transactions/bulk
     */
    bulkCreateTransactions: (data: TransactionEntity[], params: RequestParams = {}) =>
      this.request<TransactionEntity[], any>({
        path: `/transactions/bulk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Transactions
     * @name BulkRemoveTransactions
     * @summary Bulk Remove Transactions
     * @request DELETE:/transactions/bulk
     */
    bulkRemoveTransactions: (
      query: {
        /** IDs */
        ids: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/transactions/bulk`,
        method: "DELETE",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Transactions
     * @name ReadTransaction
     * @summary Read Transaction
     * @request GET:/transactions/read/{id}
     */
    readTransaction: (id: string, params: RequestParams = {}) =>
      this.request<TransactionEntity, any>({
        path: `/transactions/read/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Transactions
     * @name UpdateTransaction
     * @summary Update Transaction
     * @request PATCH:/transactions/{id}
     */
    updateTransaction: (id: string, data: TransactionEntity, params: RequestParams = {}) =>
      this.request<TransactionEntity, any>({
        path: `/transactions/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Transactions
     * @name DeleteTransaction
     * @summary Delete Transaction
     * @request DELETE:/transactions/{id}
     */
    deleteTransaction: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/transactions/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Transactions
     * @name ExportTransactionsCsv
     * @summary Export Transactions Csv
     * @request GET:/transactions/export/csv
     */
    exportTransactionsCsv: (
      query: {
        search?: string;
        limit?: number;
        page?: number;
        userId?: string;
        machineId?: string;
        year?: number;
        status: TransactionStatus;
        type: TransactionType;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/transactions/export/csv`,
        method: "GET",
        query: query,
        ...params,
      }),
  };
  events = {
    /**
     * No description
     *
     * @tags Events
     * @name EventsControllerEvents
     * @request GET:/events/{token}
     */
    eventsControllerEvents: (token: string, params: RequestParams = {}) =>
      this.request<any, UnauthorizedException>({
        path: `/events/${token}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Events
     * @name Log
     * @summary Log message from App
     * @request POST:/events/log
     */
    log: (data: LogDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/events/log`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  machines = {
    /**
     * No description
     *
     * @tags Machines
     * @name RegenerateMachineKeys
     * @summary Regenerate Machine Keys
     * @request POST:/machines/{id}/regenerate
     */
    regenerateMachineKeys: (id: string, params: RequestParams = {}) =>
      this.request<MachineEntity, any>({
        path: `/machines/${id}/regenerate`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Machines
     * @name CreateSensorData
     * @summary Create Sensor Data
     * @request POST:/machines/sensor
     */
    createSensorData: (data: SensorDataDto, params: RequestParams = {}) =>
      this.request<SensorDataEntity, any>({
        path: `/machines/sensor`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Machines
     * @name ListSensorData
     * @summary List Sensor Data
     * @request GET:/machines/sensor
     */
    listSensorData: (
      query: {
        limit?: number;
        page?: number;
        search?: string;
        machineId: string;
        type: SensorDataType;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        | {
            items: SensorDataEntity[];
            meta: {
              itemCount: number;
              totalItems: number;
              itemsPerPage: number;
              totalPages: number;
              currentPage: number;
            };
          }
        | SensorDataEntity[],
        any
      >({
        path: `/machines/sensor`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Machines
     * @name MachineDeposit
     * @summary Machine deposit
     * @request POST:/machines/deposit/{token}/{amount}
     */
    machineDeposit: (token: string, amount: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/machines/deposit/${token}/${amount}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Machines
     * @name GetUserBalance
     * @summary Machine get user balance
     * @request GET:/machines/balance/{token}
     */
    getUserBalance: (token: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/machines/balance/${token}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Machines
     * @name CreateMachine
     * @summary Create Machine
     * @request POST:/machines
     */
    createMachine: (data: MachineEntity, params: RequestParams = {}) =>
      this.request<MachineEntity, any>({
        path: `/machines`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Machines
     * @name ListMachines
     * @summary List Machines
     * @request GET:/machines
     */
    listMachines: (
      query?: {
        limit?: number;
        page?: number;
        search?: string;
        locationId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        | {
            items: MachineEntity[];
            meta: {
              itemCount: number;
              totalItems: number;
              itemsPerPage: number;
              totalPages: number;
              currentPage: number;
            };
          }
        | MachineEntity[],
        any
      >({
        path: `/machines`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Machines
     * @name BulkCreateMachines
     * @summary Bulk Create Machines
     * @request POST:/machines/bulk
     */
    bulkCreateMachines: (data: MachineEntity[], params: RequestParams = {}) =>
      this.request<MachineEntity[], any>({
        path: `/machines/bulk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Machines
     * @name BulkRemoveMachines
     * @summary Bulk Remove Machines
     * @request DELETE:/machines/bulk
     */
    bulkRemoveMachines: (
      query: {
        /** IDs */
        ids: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/machines/bulk`,
        method: "DELETE",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Machines
     * @name ReadMachine
     * @summary Read Machine
     * @request GET:/machines/{id}
     */
    readMachine: (id: string, params: RequestParams = {}) =>
      this.request<MachineEntity, any>({
        path: `/machines/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Machines
     * @name UpdateMachine
     * @summary Update Machine
     * @request PATCH:/machines/{id}
     */
    updateMachine: (id: string, data: MachineEntity, params: RequestParams = {}) =>
      this.request<MachineEntity, any>({
        path: `/machines/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Machines
     * @name DeleteMachine
     * @summary Delete Machine
     * @request DELETE:/machines/{id}
     */
    deleteMachine: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/machines/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Machines
     * @name ExportMachinesCsv
     * @summary Export Machines Csv
     * @request GET:/machines/export/csv
     */
    exportMachinesCsv: (
      query?: {
        search?: string;
        limit?: number;
        page?: number;
        locationId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/machines/export/csv`,
        method: "GET",
        query: query,
        ...params,
      }),
  };
  locations = {
    /**
     * No description
     *
     * @tags Locations
     * @name CreateLocation
     * @summary Create Location
     * @request POST:/locations
     */
    createLocation: (data: LocationEntity, params: RequestParams = {}) =>
      this.request<LocationEntity, any>({
        path: `/locations`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Locations
     * @name ListLocations
     * @summary List Locations
     * @request GET:/locations
     */
    listLocations: (
      query?: {
        limit?: number;
        page?: number;
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        | {
            items: LocationEntity[];
            meta: {
              itemCount: number;
              totalItems: number;
              itemsPerPage: number;
              totalPages: number;
              currentPage: number;
            };
          }
        | LocationEntity[],
        any
      >({
        path: `/locations`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Locations
     * @name BulkCreateLocations
     * @summary Bulk Create Locations
     * @request POST:/locations/bulk
     */
    bulkCreateLocations: (data: LocationEntity[], params: RequestParams = {}) =>
      this.request<LocationEntity[], any>({
        path: `/locations/bulk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Locations
     * @name BulkRemoveLocations
     * @summary Bulk Remove Locations
     * @request DELETE:/locations/bulk
     */
    bulkRemoveLocations: (
      query: {
        /** IDs */
        ids: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/locations/bulk`,
        method: "DELETE",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Locations
     * @name ReadLocation
     * @summary Read Location
     * @request GET:/locations/{id}
     */
    readLocation: (id: string, params: RequestParams = {}) =>
      this.request<LocationEntity, any>({
        path: `/locations/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Locations
     * @name UpdateLocation
     * @summary Update Location
     * @request PATCH:/locations/{id}
     */
    updateLocation: (id: string, data: LocationEntity, params: RequestParams = {}) =>
      this.request<LocationEntity, any>({
        path: `/locations/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Locations
     * @name DeleteLocation
     * @summary Delete Location
     * @request DELETE:/locations/{id}
     */
    deleteLocation: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/locations/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Locations
     * @name ExportLocationsCsv
     * @summary Export Locations Csv
     * @request GET:/locations/export/csv
     */
    exportLocationsCsv: (
      query?: {
        search?: string;
        limit?: number;
        page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/locations/export/csv`,
        method: "GET",
        query: query,
        ...params,
      }),
  };
  statistics = {
    /**
     * No description
     *
     * @tags Statistics
     * @name GetUserStatistics
     * @summary Get user statistics
     * @request GET:/statistics/users
     */
    getUserStatistics: (params: RequestParams = {}) =>
      this.request<StatisticsUsersDto, any>({
        path: `/statistics/users`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name GetBeveragesStatistics
     * @summary Get beverages statistics
     * @request GET:/statistics/beverages
     */
    getBeveragesStatistics: (
      query?: {
        locationId?: string;
        machineId?: string;
        /** Interval */
        interval?: "today" | "last_week" | "last_month" | "last_year";
      },
      params: RequestParams = {},
    ) =>
      this.request<StatisticsBeveragesResponseDto, any>({
        path: `/statistics/beverages`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
}
