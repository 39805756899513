import { DependencyList, useCallback, useEffect, useState } from 'react';
import { HttpResponse } from 'lib/api/api';

type ApiLoading = {
  loading: true;
  data?: undefined;
  error?: undefined;
}

type ApiError = {
  loading: false;
  error: Error;
  data?: undefined;
}

type ApiResult<T> = {
  data: T;
  loading: false;
  error?: undefined;
}

type ApiCallState<T> =
  | ApiLoading
  | ApiError
  | ApiResult<T>
  ;

type UseApiResult<T> = ApiCallState<T> & {
  reload: () => void;
  update: (data: T) => void;
};

export const useApiCall = <T extends unknown, >(fn: () => Promise<HttpResponse<T>>, deps: DependencyList = []): UseApiResult<T> => {

  const [state, setState] = useState<ApiCallState<T>>({ loading: true });

  const load = useCallback(() => {
    setState({ loading: true });
    fn().then((({ data }) => {
      setState({ data, loading: false });
    })).catch((error: Error) => {
      setState({ error, loading: false });
    });
  }, []);

  useEffect(load, [load]);

  const update = (data: T) => {
    setState({ data, loading: false });
  };

  return { ...state, reload: load, update };

};
