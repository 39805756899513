import { IonButton, IonInput, IonItem, IonLabel, IonRouterLink, NavContext, useIonToast } from '@ionic/react';
import { Alert } from 'components/Alert/Alert';
import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import * as yup from 'yup';
import { useApi } from 'lib/api';

type Props = {
  onSuccess?: () => void;
  onCancel?: () => void;
};

export const ChangeEmailForm: React.FC<Props> = ({ onSuccess, onCancel }) => {

  const [error, setError] = useState<any>();

  const { users: { changeEmail } } = useApi();

  const [presentCompleted] = useIonToast();

  const { navigate } = useContext(NavContext);

  const validationSchema = yup.object({
    email: yup.string().email('Keine gültige E-Mail Adresse').required('E-Mail Adresse eingeben'),
  });

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        try {
          await changeEmail(values, { format: 'json' });
          presentCompleted({
            color: 'dark',
            duration: 5000,
            message: 'Ihre E-Mail wurde geändert',
          });
          onSuccess?.();
          navigate('/profile?newMail=' + values.email, 'root');
        } catch (e: any) {
          setError(e.error);
        }
      }}
    >

      {({ isSubmitting, errors, touched, values, handleSubmit, handleChange }) => (

        <form onSubmit={handleSubmit}>

          <IonItem className={'ion-no-padding'}>
            <IonLabel position="stacked">Neue E-Mail-Adresse</IonLabel>
            <IonInput
              name={'email'}
              value={values.email}
              onIonChange={handleChange}
            />
          </IonItem>

          <div className={'ion-padding-top'}>

            <IonButton expand={'block'} type={'submit'} className={'ion-margin-top'} disabled={isSubmitting}>
              Speichern
            </IonButton>

            <div className={'ion-text-center ion-margin-top'}>
              <IonRouterLink routerLink={'/profile'} onClick={onCancel}>
                Abbrechen
              </IonRouterLink>
            </div>

          </div>

          {((touched.email && errors.email)) && (
            <Alert type={'error'} messages={errors.email}/>
          )}

          {error && (
            <Alert type={'error'} messages={error.message}/>
          )}

        </form>

      )}

    </Formik>
  );

};
