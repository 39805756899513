import React from 'react';
import { IonButton, IonModal } from '@ionic/react';
import { Page } from 'components/Page/Page';
import styles from './OutdatedAppModal.module.scss';
import { createGlobalState, useAsync } from 'react-use';
import { Device } from '@capacitor/device';

export const useOutdatedAppModal = createGlobalState<boolean>(false);

export const OutdatedAppModal: React.FC = () => {

  const [visible] = useOutdatedAppModal();

  const href = useAsync(async () => {
    const { platform } = await Device.getInfo();
    if (platform === 'ios') {
      return 'https://apps.apple.com/us/app/bettidrink/id1579475164';
    } else if (platform === 'android') {
      return 'https://play.google.com/store/apps/details?id=com.bettidrink.app';
    } else {
      return 'https://app.bettidrink.com';
    }
  });

  if (!href.value) {
    return null;
  }

  return (
    <IonModal isOpen={visible} backdropDismiss={false} cssClass={styles.modal}>
      <Page className={'ion-padding-top'}>

        <div className={'ion-text-center'}>
          <h2>App veraltet</h2>
          <p>
            Die App ist veraltet und muss upgedated werden
          </p>

          <div className={'ion-padding-top'}>

            <IonButton expand={'block'} className={'ion-margin-top'} href={href.value} target={'_blank'}>
              Update
            </IonButton>

          </div>
        </div>

      </Page>

    </IonModal>
  );

};
