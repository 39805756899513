import React from 'react';
import { Page } from 'components/Page/Page';
import { ChangeEmailForm } from 'components';

export const ChangeEmailPage: React.FC = () => {

  return (
    <Page>
      <h2>E-Mail-Adresse ändern</h2>
      <ChangeEmailForm/>
    </Page>
  );
};
