import { Api } from 'lib/api/api';
import { useAuthReducer } from 'lib/auth';
import { useMemo } from 'react';

export const useApi = () => {

  const baseUrl = process.env.REACT_APP_API_URL + '';

  const [auth] = useAuthReducer();

  return useMemo(() => {

    const baseApiParams: any = {
      headers: {
        'Content-Type': 'application/json',
        'X-Version': process.env.REACT_APP_VERSION,
      },
    };

    if (auth.userId) {
      baseApiParams.headers.Authorization = 'Bearer ' + auth.token;
    }

    return new Api({
      baseUrl,
      baseApiParams,
    });
  }, [baseUrl, auth]);
};
