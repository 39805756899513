import React from 'react';
import { OrderTokenQrCode } from 'components';
import { Page } from 'components/Page/Page';
import styles from './ScanPage.module.scss';
import { IonButton, IonSkeletonText } from '@ionic/react';
import { useBalance } from 'lib/common';
import cx from 'classnames';

export const ScanPage: React.FC = () => {

  const balance = useBalance();
  const isLow = balance !== undefined && balance.balance < 1;

  return (
    <Page className={cx(styles.container, { [styles.isLow]: isLow })}>

      <h2>Zeige Betti-Cam den<br/>QR-Code um zu bezahlen</h2>

      <div className={styles.qrContainer}>
        <OrderTokenQrCode/>
      </div>

      <p className={styles.balanceTitle}>{isLow ? 'Dein Guthaben ist zu gering!' : 'Dein Guthaben'}</p>
      <span className={'big-number'}>
        {balance !== undefined ? (
          <span>&euro; {balance.balance.toFixed(2)}</span>
        ) : (
          <IonSkeletonText animated style={{ width: '50%' }}/>
        )}
      </span>

      {parseFloat(balance?.pendingDeposits + '') > 0 && <p>&euro; {balance?.pendingDeposits} befinden sich in Bearbeitung</p>}

      <div className={styles.depositContainer}>
        <IonButton expand={'block'} routerLink={'/deposit'}>{isLow ? 'Jetzt aufladen' : 'Mehr aufladen'}</IonButton>
      </div>

    </Page>
  );
};
