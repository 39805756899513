import React, { useState } from 'react';
import { IonButton, IonInput, IonItem, IonLabel } from '@ionic/react';
import { useAuth } from 'lib/auth';
import { Page } from 'components/Page/Page';
import styles from './Login.module.scss';
import { PasswordInput } from 'components';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import { Alert } from 'components/Alert/Alert';
import cx from 'classnames';

export const Register: React.FC = () => {

  const { register } = useAuth();

  const [error, setError] = useState<any>();

  const validationSchema = yup.object({
    email: yup.string().email('Keine gültige E-Mail Adresse').required('E-Mail Adresse eingeben'),
    password: yup.string().required('Bitte geben Sie ein Passwort ein').min(8, 'Das Passwort muss mindestens 8 Zeichen lang sein'),
    firstName: yup.string().required('Bitte geben Sie Ihren Vornamen ein'),
    lastName: yup.string().required('Bitte geben Sie Ihren Nachnamen ein'),
    agb: yup.boolean().oneOf([true], 'Bitte akzeptieren Sie die AGB und den Datenschutz'),
  });

  return (
    <Page>

      <div className={styles.wrapper}>

        <Formik
          initialValues={{
            email: '',
            password: '',
            firstName: '',
            lastName: '',
            agb: false,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              values.email = values.email.trim();
              await register(values);
            } catch (e: any) {
              setError(e.error);
            }
          }}
        >

          {({ isSubmitting, submitCount, errors, touched, values, handleSubmit, handleChange }) => (

            <form onSubmit={handleSubmit}>

              <IonItem className={cx('ion-no-padding', { 'has-error': (touched.email && errors.email) })}>
                <IonLabel position="stacked">E-Mail-Adresse</IonLabel>
                <IonInput
                  name={'email'}
                  type={'email'}
                  value={values.email}
                  onIonChange={handleChange}
                />
              </IonItem>

              <IonItem className={cx('ion-no-padding', { 'has-error': (touched.password && errors.password) })}>
                <IonLabel position="stacked">Kennwort</IonLabel>
                <PasswordInput
                  name={'password'}
                  value={values.password}
                  onChange={handleChange}
                />
              </IonItem>

              <IonItem className={cx('ion-no-padding', { 'has-error': (touched.firstName && errors.firstName) })}>
                <IonLabel position="stacked">Vorname</IonLabel>
                <IonInput
                  name={'firstName'}
                  value={values.firstName}
                  onIonChange={handleChange}
                />
              </IonItem>

              <IonItem className={cx('ion-no-padding', { 'has-error': (touched.lastName && errors.lastName) })}>
                <IonLabel position="stacked">Nachname</IonLabel>
                <IonInput
                  name={'lastName'}
                  value={values.lastName}
                  onIonChange={handleChange}
                />
              </IonItem>

              <div className={'ion-margin-top'}>
                <Field id={'agb'} name={'agb'} type={'checkbox'}/>
                <label htmlFor={'agb'} className={submitCount > 0 && errors.agb ? 'error' : undefined}>
                  Mit Ihrer Registrierung stimmen Sie der <a href={'https://www.bettidrink.com/datenschutz'} target={'_blank'} rel="noreferrer">Datenschutzerklärung</a> und den <a href={'https://www.bettidrink.com/agb'} target={'_blank'}
                                                                                                                                                                                    rel="noreferrer">AGB</a> von bettidrink zu
                </label>
              </div>

              {((touched.email && errors.email) || (touched.password && errors.password) || (touched.firstName && errors.firstName) || (touched.lastName && errors.lastName) || (touched.agb && errors.agb)) && (
                <Alert type={'error'} messages={'Bitte überprüfen Sie Ihre Eingabe'}/>
              )}

              {error && (
                <Alert type={'error'} messages={error.message}/>
              )}

              <IonButton expand={'block'} type={'submit'} className={'ion-margin-top'} disabled={isSubmitting}>
                Registrieren
              </IonButton>

            </form>

          )}

        </Formik>

        <p className={'ion-text-center'}>- oder -</p>

        <div className={'ion-text-center ion-margin-bottom'}>
          <IonButton className={'small'} routerLink={'/login'} routerDirection={'root'}>Anmelden</IonButton>
        </div>

      </div>

    </Page>
  );
};
