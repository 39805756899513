import React from 'react';
import { TransactionPeriod, useApi, useApiCall } from 'lib/api';
import { Page } from 'components/Page/Page';
import { IonSkeletonText } from '@ionic/react';
import styles from './ChartsPage.module.scss';
import { TransactionsPdf } from 'components';
import earth from 'images/earth.svg';

export const ChartsPage: React.FC = () => {

  const { users: { getBalance } } = useApi();
  const balance = useApiCall(getBalance);

  return (
    <Page>

      <h2>Deine Transaktionen</h2>

      <div className={styles.transactions}>
        <TransactionsPdf period={TransactionPeriod.CurrentMonth}/>
        <TransactionsPdf period={TransactionPeriod.LastMonth}/>
        <TransactionsPdf period={TransactionPeriod.Total}/>
      </div>

      <div className={'ion-text-center ion-margin-top'}>
        <img src={earth} className={'ion-margin-top'} height={120} alt={'betti earth'}/>
        <h2>Eingesparte<br/>Einwegflaschen:</h2>
        <span className={'big-number'}>
          {balance.loading ? (
            <IonSkeletonText animated style={{ width: '50%' }}/>
          ) : balance.data?.orderCount}
        </span>
      </div>

    </Page>
  );
};
