import React, { useState } from 'react';
import { IonInput } from '@ionic/react';
import { InputChangeEventDetail, TextFieldTypes } from '@ionic/core';
import styles from './PasswordInput.module.scss';

interface PasswordInputProps {
  name: string;
  value: string;
  onChange: (event: CustomEvent<InputChangeEventDetail>) => void;
}

export const PasswordInput: React.FC<PasswordInputProps > = ({ name, value, onChange }) => {

  const [type, setType] = useState<TextFieldTypes>('password');

  return (
    <div className={styles.container}>
      <IonInput
        name={name}
        value={value}
        type={type}
        onIonChange={onChange}
        autocomplete="new-password"
        className={styles.input}
      />
      <span className={styles.toggle} onClick={() => setType(type === 'password' ? 'text' : 'password')}>
        <i className={'far fa-eye' + (type === 'text' ? '-slash' : '')}/>
      </span>
    </div>
  );

};
