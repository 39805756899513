import React, { useEffect, useState } from 'react';
import { AUTH_STORAGE_KEY, AuthReducerProvider, AuthState, useAuth } from 'lib/auth';
import { IonRouterOutlet } from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';
import { Login, Register, Reset } from '../pages';
import { IonReactRouter } from '@ionic/react-router';
import { Preferences } from '@capacitor/preferences';
import { useOnAwake, usePauseResume } from 'lib/common';
import { useChangeEmailModal, useOutdatedAppModal } from 'components';
import { useApi } from 'lib/api';
import { useInterval } from 'react-use';

export const AuthProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const [initialAuthState, setInitialAuthState] = useState<AuthState>();

  useEffect(() => {
    Preferences.get({ key: AUTH_STORAGE_KEY }).then((result) => {
      setInitialAuthState(result.value ? JSON.parse(result.value!) : {});
    });
  }, []);

  if (initialAuthState === undefined) {
    return null;
  }

  return (
    <AuthReducerProvider initialState={initialAuthState}>
      <AuthVerificationProvider>
        <AuthRouter>
          {children}
        </AuthRouter>
      </AuthVerificationProvider>
    </AuthReducerProvider>
  );

};

const AuthVerificationProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const { logout, userId, verify } = useAuth();
  const { events: { log }} = useApi();

  const setChangeEmailModalVisible = useChangeEmailModal()[1];
  const setOutdatedAppModalVisible = useOutdatedAppModal()[1];

  const handleVerify = async () => {
    try {
      await verify();
    } catch (error: any) {
      if (error.error?.name === 'BettidrinkEmailAddressException') {
        setChangeEmailModalVisible(true);
      } else if (error.error?.name === 'OutdatedAppVersionException') {
        setOutdatedAppModalVisible(true);
      } else if (userId && error.error) {
        await log({ message: 'Verification failed for user id' + userId + ' with error ' + error.error.name });
        await logout();
      }
    }
  };

  useEffect(() => {
    if (userId) {
      handleVerify();
    }
  }, [userId]);

  useInterval(handleVerify, 60e3);
  useOnAwake(handleVerify, 5e3);
  usePauseResume(() => {
  }, handleVerify);

  return <>{children}</>;

};

const AuthRouter: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const { userId } = useAuth();

  if (userId) {
    return <>{children}</>;
  }

  return (
    <IonReactRouter>
      <IonRouterOutlet>
        <Route path="/login" component={Login} exact={true}/>
        <Route path="/register" component={Register} exact={true}/>
        <Route path="/reset" component={Reset} exact={true}/>
        <Route render={() => <Redirect to="/login"/>}/>
      </IonRouterOutlet>
    </IonReactRouter>
  );

};
