import React, { useCallback, useEffect } from 'react';
import { useAuth } from 'lib/auth';
import { EventType } from 'lib/api';
import { QRCode } from 'react-qr-svg';
import styles from './OrderTokenQrCode.module.scss';
import { useIonLoading, useIonToast } from '@ionic/react';
import { useEvents } from 'lib/events/hooks/useEvents';
import { Haptics } from '@capacitor/haptics';

export const OrderTokenQrCode: React.FC = () => {

  const { orderToken } = useAuth();

  useEffect(() => {
    console.log('Order Token', orderToken);
  }, [orderToken]);

  const [eventSource] = useEvents();

  const [processing, dismissProgressing] = useIonLoading();
  const [presentCompleted] = useIonToast();

  const onOrderAccepted = useCallback((event: any) => {
    console.log('Order accepted: ' + event.data);
    processing({ message: 'Ihr Getränk wird zubereitet', duration: 10000 });
    Haptics.vibrate();
  }, [processing]);

  const onOrderCompleted = useCallback((event: any) => {
    console.log('Order canceled: ' + event.data);
    dismissProgressing();
    presentCompleted({
      color: 'dark',
      duration: 5000,
      message: 'Ihr Getränk ist fertig',
    });
    Haptics.vibrate();
  }, [dismissProgressing, presentCompleted]);

  const onOrderCanceled = useCallback((event: any) => {
    console.log('Order completed: ' + event.data);
    dismissProgressing();
    presentCompleted({
      color: 'danger',
      duration: 5000,
      message: 'Der Vorgang wurde abgebrochen',
    });
    Haptics.vibrate();
  }, [dismissProgressing, presentCompleted]);

  useEffect(() => {
    eventSource.addEventListener(EventType.OrderAccepted, onOrderAccepted);
    eventSource.addEventListener(EventType.OrderCompleted, onOrderCompleted);
    eventSource.addEventListener(EventType.OrderCanceled, onOrderCanceled);
    return () => {
      eventSource.removeEventListener(EventType.OrderAccepted, onOrderAccepted);
      eventSource.removeEventListener(EventType.OrderCompleted, onOrderCompleted);
      eventSource.removeEventListener(EventType.OrderCanceled, onOrderCanceled);
    };
  }, [onOrderAccepted, onOrderCompleted, onOrderCanceled, eventSource]);

  return (
    <div className={styles.container}>
      <div>
        <QRCode
          key={orderToken}
          bgColor="#ffffff"
          fgColor="#000000"
          level="M"
          style={{ width: '40vh', maxWidth: '320px', margin: '0 auto' }}
          value={orderToken!}
        />
      </div>
    </div>
  );

};
