import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonContent, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ChartsPage, DepositPage, ProfilePage, ScanPage } from 'pages';
import { AuthProvider } from 'lib/auth';
import { EventsProvider } from 'lib/events';
import { Compose } from 'lib/common/compose';
import { ChangePasswordPage } from 'pages/Profile/ChangePasswordPage';
import { ChangeEmailPage } from 'pages/Profile/ChangeEmailPage';
import { ChangeEmailModal, OutdatedAppModal } from 'components';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import 'theme/typography.scss';
import 'theme/variables.css';
import 'theme/styles.scss';
import styles from './App.module.scss';

// images
import logo from 'images/logo.svg';
import { TransactionsProvider } from 'lib/events/providers/TransactionsProvider';

const App = () => (
  <IonApp className={styles.container}>

    <div className={styles.logo}>
      <img src={logo} alt={'Bettidrink'}/>
    </div>

    <IonContent scrollX={false} scrollY={false}>
      <AuthProvider>

        <IonReactRouter>

          <Compose components={[
            EventsProvider,
            TransactionsProvider,
          ]}>

            <IonTabs>

              <IonRouterOutlet id="main" animated={false}>
                <Route path="/charts" component={ChartsPage} exact={true}/>
                <Route path="/scan" component={ScanPage} exact={true}/>
                <Route path="/profile" component={ProfilePage} exact={true}/>
                <Route path="/profile/mail" component={ChangeEmailPage} exact={true}/>
                <Route path="/profile/password" component={ChangePasswordPage} exact={true}/>
                <Route path="/deposit" component={DepositPage} exact={true} strict/>
                <Redirect to="/scan"/>
              </IonRouterOutlet>

              <IonTabBar slot="bottom">
                <IonTabButton tab="charts" href="/charts">
                  <span/>
                </IonTabButton>
                <IonTabButton tab="scan" href="/scan">
                  <span/>
                </IonTabButton>
                <IonTabButton tab="profile" href="/profile">
                  <span/>
                </IonTabButton>
              </IonTabBar>

            </IonTabs>

            <ChangeEmailModal/>
            <OutdatedAppModal/>

          </Compose>

        </IonReactRouter>
      </AuthProvider>

    </IonContent>

  </IonApp>
);

export default App;
