import React, { useCallback, useEffect, useState } from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { IonButton, useIonLoading } from '@ionic/react';
import { Alert } from 'components/Alert/Alert';
import { useHistory, useLocation } from 'react-router';
import { useEvents } from 'lib/events/hooks/useEvents';
import { EventType } from 'lib/api';

type Props = {
  amount: number;
  return_url: string;
  onReady?: () => void;
};

export const CheckoutForm: React.FC<Props> = ({ return_url, amount, onReady }) => {

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string>();
  const [processing, dismissProgressing] = useIonLoading();

  const handleSubmit = async (event: any) => {

    event.preventDefault();

    await processing({ message: 'Bezahlung wird durchgeführt' });

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: { return_url },
    });

    await dismissProgressing();

    if (error) {
      console.error(error);
      setMessage(error.message);
    }
  };

  const { pathname } = useLocation();
  const history = useHistory();

  const [eventSource] = useEvents();

  useEffect(() => {
    if (pathname !== '/deposit') {
      dismissProgressing();
    }
  }, [pathname]);

  useEffect(() => {
    const element = elements?.getElement?.('payment');
    if (element) {
      element.on('ready', () => {
        onReady?.();
      });
    }
  }, [elements]);

  const onTransactionNotification = useCallback((event: any) => {
    dismissProgressing();
    if (pathname === '/deposit') {
      history.replace('/scan');
    }
  }, [pathname, history]);

  useEffect(() => {
    eventSource.addEventListener(EventType.TransactionNotification, onTransactionNotification);
    return () => {
      eventSource.removeEventListener(EventType.TransactionNotification, onTransactionNotification);
    };
  }, [eventSource, onTransactionNotification]);

  return (
    <form onSubmit={handleSubmit}>
      <h2>Gewählter Betrag: &euro; {amount}</h2>
      <PaymentElement/>
      <div className={'ion-text-center ion-margin-top'}>
        {stripe && <IonButton type={'submit'} expand={'block'}>Jetzt bezahlen</IonButton>}
      </div>
      {message && <Alert messages={message} type={'error'}/>}
    </form>
  );
};
