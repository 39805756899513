import { createLoginAction, createLogoutAction, createVerifyAction, useAuthReducer } from 'lib/auth/ducks/auth.duck';
import { DeviceType, HttpResponse, LoginDto, LoginResponseDto, RegisterDto, useApi } from 'lib/api';
import { KeyPair } from 'lib/auth/interfaces';
import forge from 'node-forge';
import { AUTH_STORAGE_KEY } from 'lib/auth/constants';
import { Preferences } from '@capacitor/preferences';
import * as jwt from 'jsonwebtoken';
import { Device } from '@capacitor/device';

type GeneratedProps = 'publicKey' | 'deviceId' | 'deviceType' | 'deviceName' | 'deviceManufacturer' | 'deviceModel';
type BaseDto<T> = Omit<T, GeneratedProps>;

export const createKeyPair = async () => new Promise<KeyPair>((resolve, reject) => {
  forge.pki.rsa.generateKeyPair({ bits: 2048, workers: -1 }, async (err, keys) => {

    if (err) {
      reject(err);
    }

    const publicKey = forge.pki.publicKeyToPem(keys.publicKey);
    const privateKey = forge.pki.privateKeyToPem(keys.privateKey);

    resolve({ publicKey, privateKey });
  });
});

export const useAuth = () => {

  const [authState, dispatch] = useAuthReducer();
  const { auth: { login, logout, register, verify } } = useApi();

  const loginOrRegister = async (cb: (args: Pick<LoginDto, GeneratedProps>) => Promise<HttpResponse<LoginResponseDto>>) => {

    const keyPair = await createKeyPair();
    const { publicKey, privateKey } = keyPair;

    const { identifier: deviceId } = await Device.getId();
    const { platform, name: deviceName, manufacturer: deviceManufacturer, model: deviceModel } = await Device.getInfo();

    const deviceType = {
      'ios': DeviceType.Ios,
      'android': DeviceType.Android,
      'web': DeviceType.Web,
    }[platform];

    const deviceInfo = { deviceId, deviceType, deviceName, deviceManufacturer, deviceModel };

    const { data: { userId, keyId, orderToken, stripePublicKey } } = await cb({ ...deviceInfo, publicKey });

    const token = jwt.sign({ uid: userId, iss: keyId, iat: Date.now() }, privateKey, { algorithm: 'RS256' }).toString();

    await Preferences.set({
      key: AUTH_STORAGE_KEY,
      value: JSON.stringify({ userId, keyId, deviceId, keyPair, token, orderToken, stripePublicKey }),
    });

    dispatch(createLoginAction(userId, keyId, deviceId, keyPair, token, orderToken, stripePublicKey));
  };

  const handleLogout = async () => {
    await Preferences.remove({ key: AUTH_STORAGE_KEY });
    dispatch(createLogoutAction());
    logout().catch(console.error);
  };

  const handleVerification = async () => {
    const { data: { orderToken, stripePublicKey } } = await verify();
    dispatch(createVerifyAction(orderToken, stripePublicKey));
  };

  return {
    ...authState,
    login: async (data: BaseDto<LoginDto>) => loginOrRegister(args => login({ ...data, ...args })),
    register: async (data: BaseDto<RegisterDto>) => loginOrRegister(args => register({ ...data, ...args })),
    logout: handleLogout,
    verify: handleVerification,
  };
};
