import { useCallback, useEffect } from 'react';
import { EventType, useApi, useApiCall } from 'lib/api';
import { useEvents } from 'lib/events/hooks/useEvents';

export const useBalance = () => {

  const { users: { getBalance } } = useApi();

  const balance = useApiCall(getBalance);

  const [eventSource] = useEvents();

  const onBalanceChanged = useCallback((event: any) => {
    console.log('Balance changed');
    balance.update({ ...balance.data!, ...JSON.parse(event.data) });
  }, [balance]);

  useEffect(() => {
    eventSource.addEventListener(EventType.BalanceChanged, onBalanceChanged);
    return () => {
      eventSource.removeEventListener(EventType.BalanceChanged, onBalanceChanged);
    };
  }, [eventSource, onBalanceChanged]);

  return balance.data;

};
