import React, { useMemo } from 'react';
import { TransactionPeriod } from 'lib/api';
import pdfIcon from 'images/pdf.svg';
import styles from './TransactionsPdf.module.scss';
import { useAuth } from 'lib/auth';
import { Browser } from '@capacitor/browser';

type Props = {
  period: TransactionPeriod;
};

export const TransactionsPdf: React.FC<Props> = ({ period }) => {
  const { token } = useAuth();

  const label = useMemo(() => {
    if (period === TransactionPeriod.CurrentMonth) {
      return 'Aktuelles<br/>Monat';
    } else if (period === TransactionPeriod.LastMonth) {
      return 'Letztes<br/>Monat';
    } else if (period === TransactionPeriod.Total) {
      return 'Gesamte<br/>Historie';
    }
  }, [period]);

  return (
    <a onClick={() => Browser.open({ url: `${process.env.REACT_APP_API_URL}/users/transactions/${period}?token=${token}`})} className={styles.container}>
      <img src={pdfIcon} alt={label!}/>
      <span dangerouslySetInnerHTML={{ __html: label! }}/>
    </a>
  );

};
