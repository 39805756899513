import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import cx from 'classnames';
import styles from './Page.module.scss';

type PageProps = {
  className?: string;
  children?: React.ReactNode;
}

export const Page: React.FC<PageProps> = (props) => {

  return (
    <IonPage className={cx(styles.wrapper, props.className)}>

      <IonContent scrollY>
        <div className={styles.container}>
          {props.children}
        </div>
      </IonContent>

    </IonPage>
  );

};
