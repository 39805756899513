import React from 'react';
import { IonButton, IonSkeletonText, useIonAlert } from '@ionic/react';
import { useAuth } from 'lib/auth';
import { Page } from 'components/Page/Page';
import { useApi, useApiCall } from 'lib/api';
import { useLocation } from 'react-router';
import { parse } from 'querystring';

export const ProfilePage: React.FC = () => {

  const { logout } = useAuth();
  const { users: { getProfile, requestAccountDeletion } } = useApi();

  const [present] = useIonAlert();
  const [present2] = useIonAlert();

  const { search } = useLocation();
  const params = parse(search.replace('?', ''));

  const profile = useApiCall(getProfile, [params?.newMail]);

  const handleLogout = async () => {
    await logout();
  };

  const handleRemove = async (event: any) => {
    event.preventDefault();
    await present({
      // cssClass: 'my-css',
      header: 'Wollen Sie Ihren Account wirklich löschen?',
      message: 'Das Löschen ihres Accounts führt automatisch zum Verlust ihres Guthabens und ist nicht rückgängig machbar.<br/>Ihre persönlichen Daten werden endgültig gelöscht.',
      buttons: [
        {
          text: 'Abbrechen',
          cssClass: 'secondary',
        },
        {
          text: 'Löschen',
          cssClass: 'danger',
          handler: async (d) => {
            await requestAccountDeletion();
            await logout();
            await present2({
              header: 'Account erfolgreich gelöscht',
              message: 'Ihr Account und alle zugehörigen Daten wurden gelöscht',
              buttons: [
                {
                  text: 'Schließen',
                  cssClass: 'secondary',
                },
              ],
            });
          },
        }
      ],
    });
  };

  return (
    <Page>

      <h2>Kontoverwaltung</h2>

      <p className={'ion-text-center'}>
        E-Mail-Adresse: {profile.loading ? <IonSkeletonText animated style={{ width: '50%' }}/> : profile.data?.email}
      </p>

      <div className={'ion-text-center'}>
        <IonButton expand={'block'} routerLink={'/profile/mail'}>E-Mail ändern</IonButton>
        <IonButton expand={'block'} className={'ion-margin-top'} routerLink={'/profile/password'}>Passwort ändern</IonButton>
        <IonButton expand={'block'} className={'ion-margin-top'} routerLink={'/deposit'}>Guthaben aufladen</IonButton>
      </div>

      <div className={'ion-padding ion-text-center'}>
        <IonButton className={'small'} onClick={handleLogout}>
          Logout
        </IonButton>
      </div>

      <div className={'ion-text-center ion-margin-top'}>
        <h2>Impressum</h2>
        <div>
          <b>bettidrink GmbH</b><br/>
          Steinfeldstraße 25<br/>
          AT-2731 St. Egyden
        </div>
        <div className={'ion-margin-top'}>
          <b>Support</b><br/>
          +43 660 6596498<br/>
          <a href={'mailto:office@bettidrink.com'}>office@bettidrink.com</a>
        </div>
        <div className={'ion-margin-top ion-margin-bottom'}>
          <a rel="noreferrer" target={'_blank'} href={'https://www.bettidrink.com/impressum'}>
            Vollständiges Impressum
          </a><br/>
          <a rel="noreferrer" target={'_blank'} href={'https://www.bettidrink.com/agb'}>
            AGB
          </a><br/>
          <a rel="noreferrer" target={'_blank'} href={'https://www.bettidrink.com/datenschutz'}>
            Datenschutz
          </a><br/>
          <a rel="noreferrer" target={'_blank'} href={'https://www.bettidrink.com/getränke'}>
            Getränkeinformationen
          </a>
        </div>

        <a className={'ion-margin-top ion-margin-bottom'} href={'#'} onClick={handleRemove}>Account löschen</a>
      </div>

    </Page>
  );
};
