import React from 'react';
import { arrayify } from 'lib/common';
import styles from './Alert.module.scss';
import cx from 'classnames';

export interface AlertProps {
  messages: string | string[];
  type: 'error' | 'success' | 'warning';
  card?: boolean;
}

export const Alert: React.FC<AlertProps> = ({ messages, type, card }) => {

  if (!messages) {
    return null;
  }

  return (
    <div className={cx(styles.container, styles[type], { [styles.card]: card })}>
      {arrayify(messages).map(message => (
        <p key={message}>
          {type === 'error' && <i className={'far fa-times-circle'}/>}
          {type === 'success' && <i className={'far fa-check-circle'}/>}
          {type === 'warning' && <i className={'far fa-exclamation-circle'}/>}
          {message}
        </p>
      ))}
    </div>
  );

};
